import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { publicRequest, userRequest } from "../utils/ApiConfig";
import { clearCart } from "./CartSlice";
import { handleAutoLogout } from "../utils/callBack";

export const userLogin = createAsyncThunk("user/login", async (userData) => {
  try {
    const response = await publicRequest.post("/auth/login", userData);
    return response?.data;
  } catch (err) {
    if (err.response?.data) {
      throw new Error(err.response?.data);
    } else {
      throw new Error("login Failed");
    }
  }
});
export const userRegister = createAsyncThunk(
  "user/register",
  async (userData) => {
    try {
      await publicRequest.post("/auth/register", userData);
      return true;
    } catch (err) {
      if (err.response.data) {
        throw new Error(err.response.data);
      } else {
        throw new Error("Register Failed");
      }
    }
  }
);
export const updateUser = createAsyncThunk(
  "user/updateProfile",
  async ({ userId, data }, { dispatch }) => {
    try {
      const response = await userRequest.put(`/customer/${userId}`, data);
      return response.data;
    } catch (err) {
      if (err.response.data) {
        if (err.response.status === 401) {
          handleAutoLogout(dispatch);
        }
        throw new Error(err.response.data);
      } else {
        throw new Error("Register Failed");
      }
    }
  }
);
export const updateUserPassword = createAsyncThunk(
  "user/changePassword",
  async ({ userId, password, newPassword }, { dispatch }) => {
    try {
      const response = await userRequest.put(
        `/customer/${userId}/changepassword`,
        { password, newPassword }
      );
      return response.data;
    } catch (err) {
      if (err.response.data) {
        if (err.response.status === 401) {
          handleAutoLogout(dispatch);
        }
        throw new Error(err.response.data);
      } else {
        throw new Error("update password Failed");
      }
    }
  }
);
export const getUserInfo = createAsyncThunk(
  "user/getUserInfo",
  async ({ userId }, { dispatch }) => {
    try {
      const response = await userRequest.get(`/customer/${userId}`);
      return response.data;
    } catch (err) {
      if (err.response.data) {
        if (err.response.status === 401) {
          handleAutoLogout(dispatch);
        }
        throw new Error(err.response.data);
      } else {
        throw new Error("Fetching user info Failed");
      }
    }
  }
);

export const updateUserEmail = createAsyncThunk(
  "user/changeEmail",
  async ({ userId, email, name }, { dispatch }) => {
    try {
      const response = await userRequest.put(`/customer/${userId}/resetEmail`, {
        email: email,
        name: name,
      });
      const updatedUser = response.data;
      return updatedUser;
    } catch (err) {
      if (err.response.data) {
        if (err.response.status === 401) {
          handleAutoLogout(dispatch);
        }
        throw new Error(err.response.data);
      } else {
        throw new Error("update email Failed");
      }
    }
  }
);
// ---User Address requests---

// Add new user address
export const addnewUserAddress = createAsyncThunk(
  "user/addAddress",
  async ({ userRefId, data, isBillingAddress }, { dispatch }) => {
    try {
      const response = await userRequest.post(
        `/customer/${userRefId}/address`,
        {
          ...data,
          default_shipping: !isBillingAddress,
          default_billing: isBillingAddress,
          prefix: isBillingAddress ? "billing" : "shipping",
        }
      );
      return response.data;
    } catch (err) {
      if (err.response.data) {
        if (err.response.status === 401) {
          handleAutoLogout(dispatch);
        }
        throw new Error(err.response.data);
      } else {
        throw new Error("Failed adding new address");
      }
    }
  }
);

// Updates user address
export const updateUserAddress = createAsyncThunk(
  "user/updateAddress",
  async ({ userRefId, data }, { dispatch }) => {
    try {
      const response = await userRequest.put(
        `/customer/${userRefId}/address/${data.id}`,
        data
      );
      return response.data;
    } catch (err) {
      if (err.response.data) {
        if (err.response.status === 401) {
          handleAutoLogout(dispatch);
        }
        throw new Error(err.response.data);
      } else {
        throw new Error("Failed adding new address");
      }
    }
  }
);
// Delete user address by id
export const deleteUserAddress = createAsyncThunk(
  "user/deleteAddress",
  async ({ userRefId, addressId }, { dispatch }) => {
    try {
      await userRequest.delete(
        `/customer/${userRefId}/address/${addressId}`,
        {}
      );
      return addressId;
    } catch (err) {
      if (err.response.data) {
        if (err.response.status === 401) {
          handleAutoLogout(dispatch);
        }
        throw new Error(err.response.data);
      } else {
        throw new Error(`Failed deleting address: ${addressId}`);
      }
    }
  }
);
// update user Pref by id
export const updateUserPrefNot = createAsyncThunk(
  "user/udatePrefNoti",
  async ({ userId, prefrence }, { dispatch }) => {
    try {
      const response = await userRequest.put(
        `/customer/${userId}/notifcation`,
        {
          prefrence: prefrence,
        }
      );
      const updatedUserData = response.data;
      return updatedUserData;
    } catch (err) {
      if (err.response.data) {
        if (err.response.status === 401) {
          handleAutoLogout(dispatch);
        }
        throw new Error(err.response.data);
      } else {
        throw new Error("Failed deleting address");
      }
    }
  }
);

export const logoutUser = () => (dispatch) => {
  // Perform any side effects here
  localStorage.clear(); // Corrected: `clear` method does not take arguments
  dispatch(userLogout()); // Dispatch the userLogout action to update the state
  dispatch(clearCart()); // Dispatch any additional actions as needed
};

const userSlice = createSlice({
  name: "user",
  initialState: {
    currentUser: null,
    isLoading: false,
    loginError: {
      status: false,
      message: null,
    },
    error: {
      status: false,
      message: null,
    },
    successMssg: null,
    isRegistered: null,
    isUpdated: null,
  },
  reducers: {
    userLogout: (state) => {
      state.currentUser = null;
      localStorage.clear();
      state.error = {
        status: false,
        message: null,
      };
      state.isRegistered = null;
      state.isUpdated = null;
    },
    resetState: (state) => {
      state.error = {
        status: false,
        message: null,
      };
      state.successMssg = null;
      state.isRegistered = null;
      state.isUpdated = null;
    },
    resetError: (state) => {
      state.error = {
        status: false,
        message: null,
      };
      state.loginError = {
        status: false,
        message: null,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(userLogin.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(userLogin.fulfilled, (state, action) => {
        const { token, ...userWithoutToken } = action.payload;
        state.currentUser = userWithoutToken;
        state.isLoading = false;
        state.loginError = {
          status: false,
          message: null,
        };
        localStorage.setItem("token", action.payload.token);
      })
      .addCase(userLogin.rejected, (state, action) => {
        state.isLoading = false;
        state.loginError = {
          status: true,
          message: action.error.message || "Login failed.",
        };
      })
      .addCase(userRegister.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(userRegister.fulfilled, (state) => {
        state.isLoading = false;
        state.isRegistered = true;
        state.error = {
          status: false,
          message: null,
        };
      })
      .addCase(userRegister.rejected, (state, action) => {
        state.isLoading = false;
        state.error = {
          status: true,
          message: action.error.message || "Register failed.",
        };
      })
      .addCase(updateUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isUpdated = true;
        state.currentUser = action.payload;
        state.error = {
          status: false,
          message: null,
        };
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = {
          status: true,
          message: action.error.message || "update user failed.",
        };
      })
      .addCase(addnewUserAddress.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addnewUserAddress.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isUpdated = true;
        state.currentUser.addresses = action.payload.addresses;
        state.error = {
          status: false,
          message: null,
        };
      })
      .addCase(addnewUserAddress.rejected, (state, action) => {
        state.isLoading = false;
        state.error = {
          status: true,
          message: action.error.message || "adding new address.",
        };
      })
      .addCase(updateUserAddress.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateUserAddress.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isUpdated = true;
        state.currentUser.addresses = action.payload.addresses;
        state.error = {
          status: false,
          message: null,
        };
      })
      .addCase(updateUserAddress.rejected, (state, action) => {
        state.isLoading = false;
        state.error = {
          status: true,
          message: action.error.message || "updating address.",
        };
      })
      .addCase(deleteUserAddress.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteUserAddress.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isUpdated = true;
        const currentUserAddresses = state.currentUser.addresses;
        const deletedAddressId = action.payload;
        const filteredAddress = currentUserAddresses.filter(
          (add) => add.id !== deletedAddressId
        );
        state.currentUser.addresses = filteredAddress;
        state.error = {
          status: false,
          message: null,
        };
      })
      .addCase(deleteUserAddress.rejected, (state, action) => {
        state.isLoading = false;
        state.error = {
          status: true,
          message: action.error.message || "Failed Deleing address.",
        };
      })
      .addCase(updateUserPassword.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateUserPassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isUpdated = true;
        state.successMssg = action.payload.message;
        state.error = {
          status: false,
          message: null,
        };
      })
      .addCase(updateUserPassword.rejected, (state, action) => {
        state.isLoading = false;
        state.error = {
          status: true,
          message: action.error.message || "Failed updating password.",
        };
      })
      .addCase(updateUserEmail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateUserEmail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isUpdated = true;
        state.currentUser = { ...state.currentUser, ...action.payload };
        state.error = {
          status: false,
          message: null,
        };
      })
      .addCase(updateUserEmail.rejected, (state, action) => {
        state.isLoading = false;
        state.error = {
          status: true,
          message: action.error.message || "Failed updating email.",
        };
      })
      .addCase(getUserInfo.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserInfo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.currentUser = { ...state.currentUser, ...action.payload };
        state.error = {
          status: false,
          message: null,
        };
      })
      .addCase(getUserInfo.rejected, (state, action) => {
        state.isLoading = false;
        state.error = {
          status: true,
          message: action.error.message || "Failed updating email.",
        };
      })
      .addCase(updateUserPrefNot.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateUserPrefNot.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isUpdated = true;
        state.currentUser = { ...state.currentUser, ...action.payload };
        state.error = {
          status: false,
          message: null,
        };
      })
      .addCase(updateUserPrefNot.rejected, (state, action) => {
        state.isLoading = false;
        state.error = {
          status: true,
          message: action.error.message || "Failed updating user Pref",
        };
      });
  },
});
export const { resetError, userLogout, resetState } = userSlice.actions;
export const userReducer = userSlice.reducer;
